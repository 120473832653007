import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 50.7876 24.457 L 49.0258 29.317 Q 47.1426 28.4058 45.1678 27.859 A 13.9814 13.9814 90 0 0 43.3064 27.4856 Q 42.3449 27.356 41.2587 27.3228 A 24.7285 24.7285 90 0 0 40.5208 27.3123 A 12.8628 12.8628 90 0 0 35.7183 28.1968 A 12.1387 12.1387 90 0 0 33.5645 29.317 A 12.8588 12.8588 90 0 0 29.6976 33.3152 A 16.3887 16.3887 90 0 0 28.7353 35.0883 Q 27.1105 38.5607 26.9841 43.3754 A 31.3421 31.3421 90 0 0 26.9736 44.2008 A 25.4316 25.4316 90 0 0 27.347 48.6695 A 18.7896 18.7896 90 0 0 28.7045 53.131 A 15.599 15.599 90 0 0 30.8834 56.6715 A 13.0143 13.0143 90 0 0 33.5038 59.0845 A 11.9694 11.9694 90 0 0 40.2746 61.2091 A 14.5152 14.5152 90 0 0 40.5208 61.2108 Q 43.4976 61.2108 45.6846 60.4817 Q 47.8716 59.7527 49.9371 58.5985 L 51.6988 63.3978 Q 49.4511 64.6128 46.565 65.4333 A 20.4071 20.4071 90 0 1 43.5486 66.0351 Q 42.0412 66.2255 40.3418 66.2498 A 34.8818 34.8818 90 0 1 39.8526 66.253 A 19.6733 19.6733 90 0 1 34.1874 65.4624 A 16.9857 16.9857 90 0 1 30.0718 63.6108 A 17.3745 17.3745 90 0 1 24.0713 57.2652 A 21.2115 21.2115 90 0 1 23.4501 56.0778 A 22.8485 22.8485 90 0 1 21.5668 50.1745 Q 21.0954 47.5833 21.0808 44.6341 A 38.1672 38.1672 90 0 1 21.0808 44.4438 Q 21.0808 37.9435 23.48 32.932 A 19.2189 19.2189 90 0 1 27.7196 27.0806 A 18.14 18.14 90 0 1 30.284 25.0953 A 17.8427 17.8427 90 0 1 37.9871 22.415 A 22.6549 22.6549 90 0 1 40.5816 22.27 Q 43.6191 22.27 46.1098 22.8475 Q 48.6006 23.4243 50.7876 24.457 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
